<template>
  <!-- 注册身份认证 -->
  <div
    class="container"
    v-loading="loading"
    element-loading-text="上传中，请稍后"
    element-loading-spinner="el-icon-loading"
  >
    <div class="header-h3">上传二代身份证 <span>（支持JPG 、JPEG、 PNG图片格式，图片大小不要超过10M）</span></div>
    <div class="uploadBox">
      <span
        style="position: relative"
        :class="status ? 'sfzB' : ''"
        @mouseover="status = true"
      >
        <el-upload
          class="avatar-uploader"
          list-type="picture-card"
          action="#"
          :show-file-list="false"
          :http-request="uploadPicture"
        >
          <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
          <div v-else>
            <img
              v-if="status"
              style="width:80px;height: 50px;margin-top: 60px;"
              src="../../../assets/images/ICON-1@2x.png"
            />
            <img
              v-if="!status"
              style="width:80px;height: 50px;margin-top: 60px;"
              src="../../../assets/images/ICON-2@2x.png"
            />
            <p class="title">点击上传身份证人像面</p>
          </div>
        </el-upload>
        <i
          @click="delate(1)"
          v-if="imageUrl1 != ''"
          class="el-icon-delete"
          style="position: absolute; right: 0; top: 0; font-size: 15px"
        ></i>
      </span>
      <span
        style="position: relative;margin-left: 30px"
        :class="!status ? 'sfzB' : ''"
        @mouseover="status = false"
      >
        <el-upload
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          list-type="picture-card"
          :http-request="uploadPicture2"
        >
          <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
          <div v-else>
            <img
              v-if="!status"
              style="width:80px;height: 50px;margin-top: 60px;"
              src="../../../assets/images/ICON-1@2x.png"
            />
            <img
              v-if="status"
              style="width:80px;height: 50px;margin-top: 60px;"
              src="../../../assets/images/ICON-2@2x.png"
            />
            <p class="title">
              点击上传身份证国徽面
            </p>
          </div>
        </el-upload>
        <i
          v-if="imageUrl2 != ''"
          class="el-icon-delete"
          @click="delate(2)"
          style="
            position: absolute;
            right: 0;
            font-size: 15px;
            top: 0;
            z-index: 1000;
          "
        ></i>
      </span>
    </div>
    <div class="signbox">
      注：身份证信息金用于对考生身份的核实，我们会采取适当的措施以确保考生个人信息的安全于保密，承诺不向任何第三方披露，或用于与考生身份无关的其他用途。
    </div>
    <div class="header-h3">证件上传示例</div>
    <div class="example">
      <div style="width: 100%; margin-top: 20px; margin-bottom: 10px">
        正确示例
      </div>
      <div class="imgBox">
        <div class="imgBox-init">
          <img
            class="exampleImg"
            src="../../../assets/images/idcard01@2x.png"
          />
          <span class="errorSign">身份证人像面</span>
        </div>
        <div class="imgBox-init">
          <img
            class="exampleImg"
            style="margin-left: 30px"
            src="../../../assets/images/idcard02@2x.png"
          />
          <span class="errorSign">身份证国徽面</span>
        </div>
        <div class="radioBox">
          <ul>
            <li style="display: flex; align-items: center; margin-top: 20px">
              <span class="radio"><span class="radio-inner"></span></span
              ><span>四角平整</span>
            </li>
            <li style="display: flex; align-items: center; margin-top: 20px">
              <span class="radio"><span class="radio-inner"></span></span
              ><span>亮度均匀</span>
            </li>
            <li style="display: flex; align-items: center; margin-top: 20px">
              <span class="radio"><span class="radio-inner"></span></span
              ><span>照片清晰</span>
            </li>
          </ul>
        </div>
      </div>
      <div style="width: 100%; margin-top: 20px; margin-bottom: 20px">
        错误示例
      </div>
      <div class="imgBox">
        <div class="imgBox-init">
          <img class="errorImg" src="../../../assets/images/lack@2x.png" />
          <span class="errorSign">缺边</span>
        </div>
        <div class="imgBox-init">
          <img
            class="errorImg errorImg1"
            src="../../../assets/images/slur@2x.png"
          />
          <span class="errorSign">模糊</span>
        </div>
        <div class="imgBox-init">
          <img
            class="errorImg errorImg1"
            src="../../../assets/images/flash@2x.png"
          />
          <span class="errorSign">闪光</span>
        </div>
      </div>
    </div>
    <div class="btnBox">
      <div style="margin:0 auto; width: 400px">
        <el-button
          @click="skip()"
          style="background:#fff;border:1px solid #563279;color:#563279"
          >跳 过</el-button
        >
        <el-button :disabled="isDisable" @click="uploadConfirm()">下一步</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getIdCard, checkIdCard, setIdCard } from "r/index/identity";
import { upload } from "r/request";

export default {
  name: "Identity",
  data() {
    return {
      imageUrl1: "",
      imageUrl2: "",
      token: "",
      dialogVisible: false,
      fileList: {},
      showbtn1: true,
      file: "",
      loading: false,
      status: true, // 正反面颜色
      isDisable: false//禁用 false-不禁用，true-禁用
    };
  },
  created() {
    if (
      !window.localStorage.getItem(
        "id_card_" + window.localStorage.getItem("sfzNum")
      ) ||
      typeof JSON.parse(
        window.localStorage.getItem(
          "id_card_" + window.localStorage.getItem("sfzNum")
        )
      )[0] == "undefined" ||
      typeof JSON.parse(
        window.localStorage.getItem(
          "id_card_" + window.localStorage.getItem("sfzNum")
        )
      )[1] == "undefined"
    ) {
      this.getIdCardPic();
    } else {
      this.fileList = JSON.parse(
        window.localStorage.getItem(
          "id_card_" + window.localStorage.getItem("sfzNum")
        )
      );
      //显示身份证正面
      this.imageUrl1 = this.fileList[0][0].url;
      //显示身份证背面
      this.imageUrl2 = this.fileList[1][0].url;
    }
  },
  methods: {
    //获取token
    getToken() {
      this.token = window.localStorage.getItem("index-token");
    },
    //下一步认证
    uploadConfirm() {
      if (!this.fileList[0]) {
        this.$message.error("请上传身份证人像面");
      }
      if (!this.fileList[1]) {
        this.$message.error("请上传身份证国徽面");
      }
      if(this.isDisable){
        return;
      }
      this.isDisable = true;
      setIdCard()
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.isDisable = false;
            window.localStorage.setItem("is_check_id_card", 1);
            return this.$router.replace({ name: "StepThree" });
          } else {
            this.isDisable = false;
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          this.isDisable = false;
          this.$message.error(res.message);
        });
    },
    //获取身份认证-回显
    async getIdCardPic() {
      const { data: res } = await getIdCard();
      console.log(res, "返回图片信息");
      if (res.code === 200) {
        var fileList = {};
        if (res.pro) {
          fileList[0] = [
            {
              url: res.pro,
              status: "done",
            },
          ];
          this.imageUrl1 = res.pro;
        }
        if (res.back) {
          fileList[1] = [
            {
              url: res.back,
              status: "done",
            },
          ];
          this.imageUrl2 = res.back;
        }
        this.fileList = fileList;
        console.log(this.fileList, "回显图片");
        window.localStorage.setItem(
          "id_card_" + window.localStorage.getItem("sfzNum"),
          JSON.stringify(fileList)
        );
      } else {
        console.log("请求失败");
        this.$message.error(res.message);
      }
    },
    //上传身份证正面
    uploadPicture(params) {
      this.file = params.file;
      let raw = params.file;
      let image = {
        name: raw.name,
        size: raw.size,
        pro: 1,
        type: raw.type,
      };
      this.getOssConfig(image, params.file, 0);
    },
    //上传身份正反面
    uploadPicture2(params) {
      this.file = params.file;
      let raw = params.file;
      let image = {
        name: raw.name,
        size: raw.size,
        pro: 2,
        type: raw.type,
      };
      this.getOssConfig(image, params.file, 1);
    },
    // 获取身份证上传ossconfig
    async getOssConfig(image, file, type) {
      this.loading = true;
      await checkIdCard({ image: image })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            let oss_url = res.data.config.host;
            let filename = res.data.config.file;
            let formData = new FormData();
            formData.append("OSSAccessKeyId", res.data.config.accessid);
            formData.append("policy", res.data.config.policy);
            formData.append("signature", res.data.config.signature);
            formData.append("filename", res.data.config.file);
            formData.append("key", res.data.config.savefile);
            formData.append("callback", res.data.config.callback);
            formData.append("success_action_status", 200);
            formData.append("file", file);
            console.log("已经上传上", this.fileList);
            this.fileList[type] = [
              {
                url: filename,
                status: "uploading",
              },
            ];
            upload(oss_url, formData)
              .then((ress) => {
                console.log(ress);
                if (ress.data.code == 200) {
                  this.loading = false;
                  this.fileList[type] = [
                    {
                      url: filename + `?t=${new Date().getTime()}`,
                      status: "done",
                    },
                  ];
                  window.localStorage.setItem(
                    "id_card_" + window.localStorage.getItem("sfzNum"),
                    JSON.stringify(this.fileList)
                  );
                  console.log("asik");
                  this.$message.success("上传成功");
                  if (type === 0) {
                    //显示身份证正面
                    this.imageUrl1 = this.fileList[0][0].url;
                  } else if (type === 1) {
                    //显示身份证背面
                    this.imageUrl2 = this.fileList[1][0].url;
                  }
                } else {
                  this.loading = false;
                  this.fileList[type] = [
                    {
                      status: "failed",
                    },
                  ];
                  delete this.fileList[type];
                  window.localStorage.setItem(
                    "id_card_" + window.localStorage.getItem("sfzNum"),
                    JSON.stringify(this.fileList)
                  );
                  this.$message.error(ress.data.message);
                }
              })
              .catch((err) => {
                this.fileList[type] = [
                  {
                    status: "failed",
                  },
                ];
                delete this.fileList[type];
                window.localStorage.setItem(
                  "id_card_" + window.localStorage.getItem("sfzNum"),
                  JSON.stringify(this.fileList)
                );
                this.loading = false;
                this.$message.error(err);
              });
          } else {
            this.loading = false;
            this.$message.error(res.data.message);
            this.getIdCardPic();
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
          this.getIdCardPic();
        });
    },
    //删除图片
    delate(type) {
      if (type == 1) {
        this.imageUrl1 = "";
      }
      if (type == 2) {
        this.imageUrl2 = "";
      }
    },
    //下一步
    async toselfInfo() {
      console.log("xiayibu");
      console.log(this.token);
      const { data: res } = await setIdCard({ token: this.token });
      console.log(res);
      if (res.code === 200) {
        console.log("身份已经验证成功");
      }
      // this.$router.replace({
      //   name: "selfinfo",
      // });
    },
    // 跳过
    skip() {
      this.$router.replace({ name: "StepThree" });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  .header-h3 {
    width: 100%;
    border-bottom: 1.5px solid #dddcdc;
    margin-top: 10px;
    padding-bottom: 10px;
    margin-top: 30px;
    font-size: 16px;
    span{
      height: 12px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 12px;
    }
  }
  .uploadBox {
    width: 100%;
    display: flex;
    padding-top: 20px;
    padding-left: 0px;
    position: relative;
    .el-icon-delete {
      position: relative;
      color: #ffffff;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .signbox {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #ee7602;
    line-height: 18px;
    margin-top: 24px;
    font-family: PingFang SC;
  }
  .example {
    width: 100%;
    display: flex;
    flex-direction: column;
    .imgBox {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .exampleImg {
        width: 254px;
        height: 146px;
        border-radius: 6px;
        // border: 10px solid #f5f5f5;
      }
      .radioBox {
        display: flex;
        flex-direction: column;
        margin-left: 30px;
        margin-top: -40px;
        .radio {
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background: #563279;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 109px;
          margin-right: 10px;
          .radio-inner {
            width: 8px;
            height: 8px;
            border-radius: 5px;
            background: #ffffff;
            opacity: 0.5;
          }
        }
      }
      .imgBox-init {
        display: flex;
        flex-direction: column;
        align-items: center;
        .errorImg {
          width: 165px;
          height: 98px;
        }
        .errorImg1 {
          margin-left: 22px;
        }
        .errorSign {
          margin-top: 10px;
        }
      }
    }
  }
  .btnBox {
    width: 100%;
    margin-top: 59px;
  }
}
.title {
  margin-top: 15px;
  font-size: 14px;
  color: #cccccc;
}

::v-deep .el-upload--picture-card {
  width: 350px !important;
  height: 220px !important;
  background: #fff;
  border: 2px dotted #ccc;
  line-height: unset !important;
}
::v-deep .avatar {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
::v-deep .el-radio__label {
  margin-top: 20px !important;
  color: #000 !important;
}
::v-deep .el-button {
  background: #563279;
  color: #fff;
  border: 1px solid #563279;
  width: 140px;
}
::v-deep .sfzB {
  .el-upload--picture-card {
    border: 2px dotted #563279;
  }
  .title {
    color: #563279;
  }
}
</style>
