<template>
  <div id="main">
    <el-main>
      <div class="stepBox">
        <comm-step :active="1" :percentage="50" :type="6"></comm-step>
        <identity></identity>
      </div>
    </el-main>
  </div>
</template>

<script>
import commStep from "c/commStep";
import Identity from "c/index/register/Identity";

export default {
  name: "StepTwo",
  data() {
    return {
      percentage: 50,
    };
  },
  created(){
    this.$emit('getStatus', 2)
  },
  components: {
    commStep,
    Identity,
  },
};
</script>

<style scoped lang="scss">
#main {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  .el-main {
    max-width: 1300px;
    margin: 6px auto;
    padding: 20px 50px;
    height: auto;
  }
  .stepBox {
    // width: 70%;
    background: #fff;
    margin-top: 10px;
    padding: 60px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
